import * as Sentry from "@sentry/browser";

import * as config from '../../webpack/main.config';
import { ErrorTypes, ErrorWithCause, iterateErrorCause, NetworkError, patchFetchApi } from "../utils/errors";
import { AxiosError } from "axios";

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  debug: false,
  enabled: process.env.NODE_ENV !== 'development',
  // Alternatively, use `process.env.npm_package_version` for a dynamic release version
  // if your build tool supports it.
  release: config.revision,
  integrations: [
    Sentry.browserTracingIntegration(),
  ],
  environment: process.env.APP_ENV,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
});

patchFetchApi();

Sentry.addEventProcessor((event: Sentry.Event, hint: Sentry.EventHint) => {
  const error = hint.originalException as (ErrorWithCause | AxiosError);
  
  if(error instanceof AxiosError) {
    if (error.code === AxiosError.ERR_NETWORK) {
      event.level = 'warning';
    } else if (error.response && error.response.data) {
      let contexts = {
        ...event.contexts
      };
      contexts.errorResponse = {
        data: error.response.data
      };
      event.contexts = contexts;
    }
  } else {
    for (const cause of iterateErrorCause(error)) {
      if (cause.name === ErrorTypes.NETWORK_ERROR) {
        event.level = 'warning';
        break;
      }
    }
  }
  return event;
});