export enum ErrorTypes {
  NETWORK_ERROR = 'NETWORK_ERROR',
  UNEXPECTED_ERROR = 'UNDEXPECTED_ERROR'
}

export class ErrorWithCause extends Error {
  public cause: any = {};
  constructor(message: string, cause?: unknown) {
    super(message);
    this.cause = cause;
  }
}

export class NetworkError extends ErrorWithCause {
  constructor(message: string, cause?: unknown) {
    super(message, cause);
    this.name = ErrorTypes.NETWORK_ERROR;
  }
}

export class UnexpectedError extends ErrorWithCause {
  constructor(message: string, cause?: unknown) {
    super(message, cause);
    this.name = ErrorTypes.UNEXPECTED_ERROR;
  }
}

export function patchFetchApi() {
  const {fetch: origFetch, Request} = globalThis;
  globalThis.fetch = async function (this: unknown, ...args) {
    const request = new Request(...args);
    const stack = new Error().stack;
    
    try {
      return await origFetch.call(this, request);
    } catch (err) {
      err.stack = stack;

      if (err.name === 'AbortError') throw err;
      throw new NetworkError('Network Error', err);
    }
    
  }
}

export function iterateErrorCause(error: ErrorWithCause): ErrorWithCause[] {
  let causes: ErrorWithCause[] = [];
  causes.push(error);
  if (error.cause) {
    return causes.concat(iterateErrorCause(error.cause));
  }
  return causes;
}